<template>
  <div>

    <section>
      <div class="home_article2">
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="home_welcome_message col-md-10 text-center">
              <img
                src="../../assets/logo.png"
                width="50px"
                class="mb-4"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Experience ancient architecture, calm country side, delicious local and international foods, amazing regional diversity,
                and fantastic weather.
                We are located in the South-west Uganda, in a small village called Ruhija, on the door-steps of
                Bwindi Impenetrable National Park. A tropical Eden, with lush rain-forests, waterfalls, birds, butterflies and above all -
                the rare mountain gorillas.
              </p>
              <el-button
                data-aos="fade-up"
                data-aos-duration="2000"
                class="mt-4"
                style="background-color: #513F03; color: white; width: 200px;"
                @click="$router.push({path: '/accommodation'})"
              >
                BOOK A STAY
              </el-button>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <article class="home_article3 d-flex align-items-center justify-content-center">
        <div style="width: 95%; background-color: #FEF9F6;">
          <div class="cottages_heading">
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <p class="trekkers_word_style">
                <small>TREKKER'S TAVERN COTTAGES</small>
              </p>
              <h2>Our Amazing Cottages</h2>
            </div>
            <span class="el-icon-right"></span>
          </div>
          <div class="">
            <div
              class=""
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <carousel
                ref="preloaderCarouselRef"
                :key="carouselKey"
                :autoWidth="true"
                :dots="false"
                :navText="[
            `<i class='fas fa-chevron-left'></i>`,
            `<i class='fas fa-chevron-right'></i>`,
          ]"
              >
                <div
                  v-for="(item, index) in cottage_blocks"
                  :key="index"
                  class="ml-md-0 cottages_block"
                >
                  <img :src="item.image" />
                  <div class="welcome_description">
                    <div>
                      <div>
                        <h3 class="heading mb-2">{{item.heading}}</h3>
                        <small class="wel_text">{{item.brief_description}}</small>
                      </div>
                      <div>
                        <ul class="mt-2">
                          <li>
                            <i class="fas fa-shower"></i>
                          </li>
                          <li>
                            <i class="fas fa-wifi"></i>
                          </li>
                          <li>
                            <i class="fas fa-tv"></i>
                          </li>
                          <li>
                            <i class="el-icon-phone-outline"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>

        </div>
      </article>
    </section>

    <section>
      <article class="ttc_activities">
        <div class="activities_heading">
          <div
            class="text-center"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <p class="trekkers_word_style">
              <small>TREKKER'S TAVERN COTTAGES</small>
            </p>
            <h2 class="text-center">Experience the Activities</h2>
          </div>
        </div>
        <div class="ttc_activities_grid">
          <div class="ttc_activity_description d-flex align-items-center">
            <div>
              <h2
                data-aos="fade-up"
                data-aos-duration="2000"
              >Birding</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Bird Watching in Bwindi Impenetrable National Park is commonly done along the main trail,
                the Buhoma Waterfall Trail, Bamboo Zone and Mubwindi Swamp Trail in Ruhiija.
                Best time for birding in Uganda; November-April; migratory birds are available.
                December to February; best time for birding. There are less rains in December to February.
                May and June; the food supply is abundant and it is the main nesting season
                March-may; there are usually heavy rains and the hiking trails tend to be slippery. The mist may also be too much.
              </p>
              <el-button
                class="mt-2"
                style="background-color: #513F03; color: white; width: 200px;"
                @click="$router.push({path: '/accommodation'})"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                VIEW MORE
              </el-button>
            </div>
          </div>
          <div class="ttc_birding">
            <img
              src="../../assets/images/Birding-Safaris-Uganda.jpg"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
          </div>
        </div>

        <div class="ttc_activities_grid">
          <div>
            <img
              src="../../assets/images/Hiking-Trails-in-Bwindi-Impenetrable-National-Park.jpg"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
          </div>
          <div class="ttc_activity_description d-flex align-items-center">
            <div>
              <h2
                data-aos="fade-up"
                data-aos-duration="2000"
              >Hiking in Bwindi Impenetrable Forest</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Hiking trails in Bwindi Impenetrable National Park are some of the best in the world list.
                There are mainly six main hiking trails in the Buhoma region of Bwindi; as you hike through,
                you will experience various primate species, beautiful butterflies, various tree species, and bird species.
                Bwindi Impenetrable National Park is a known UNESCO World Heritage Site in the Southwestern Uganda covering
                331 square kilometers. It is also famous for mountain gorillas.
              </p>
              <el-button
                class="mt-2"
                style="background-color: #513F03; color: white; width: 200px;"
                @click="$router.push({path: '/accommodation'})"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                VIEW MORE
              </el-button>
            </div>
          </div>
        </div>

        <div class="ttc_activities_grid">
          <div class="ttc_activity_description d-flex align-items-center">
            <div>
              <h2
                data-aos="fade-up"
                data-aos-duration="2000"
              >Gorilla Trekking</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Mountain Gorilla tracking in Bwindi is done in the four regions in
                Bwindi National Park and these include; Ruhiija, Buhoma, Nkuringo and Rushaga
                gorilla tracking areas. However, Buhoma and Nkuringo regions in the south of
                Bwindi are the only authorized sectors for Gorilla trekking.
                The most famous gorilla trekking sector in Bwindi Impenetrable National Park is
                Buhoma and it was the first to be established for gorilla trekking under the
                Uganda Wildlife Authority.
              </p>
              <el-button
                class="mt-2"
                style="background-color: #513F03; color: white; width: 200px;"
                @click="$router.push({path: '/accommodation'})"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                VIEW MORE
              </el-button>
            </div>
          </div>
          <div class="ttc_trekking">
            <img
              src="../../assets/images/gorilla-trekking-700x450.jpg"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
          </div>
        </div>

        <div class="ttc_activities_grid">
          <div>
            <img
              src="../../assets/images/Batwa-Dance.jpg"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
          </div>
          <div class="ttc_activity_description d-flex align-items-center">
            <div>
              <h2
                data-aos="fade-up"
                data-aos-duration="2000"
              >Community Walks</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Known as the “Keepers of the Forest” the enchanting Batwa pygmies of the
                Bwindi Impenetrable National Park are a hunter-gatherer tribe that has lived
                side-by-side with the wildlife of this ancient park for thousands of years.
                Strap on your hiking boots for this full-day adventure as it kick-starts with a 3-hour
                hike through the forest to a Batwa settlement.
                At this Batwa homestead observe how the women prepare, cook and serve a meal.
                Engage with medicine men and learn about the medicinal properties of the lush forest flora.
                Hear ancient legends and traditional songs. After a warm welcome, your 1-hour cultural
                immersion begins.
              </p>
              <el-button
                class="mt-2"
                style="background-color: #513F03; color: white; width: 200px;"
                @click="$router.push({path: '/accommodation'})"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                VIEW MORE
              </el-button>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="affiliates_section">
        <div class="container text-center">
          <h2
            class="mb-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >Our Affiliates</h2>
          <div
            class="row"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="affiliates_grid">
                <div>
                  <img
                    src="../../assets/images/group_1_2da16e4c.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../../assets/images/group_1_3d16ea69.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../../assets/images/group_1_4332315c.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../../assets/images/group_1_57c3a836.webp"
                    alt=""
                    width="100%"
                  >
                </div>
                <div>
                  <img
                    src="../../assets/images/group_1_6d305674.webp"
                    alt=""
                    width="100%"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </article>
    </section>

  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: {
    carousel,
  },
  data() {
    return {
      carouselKey: 1,
      rooms: [
        {
          id: "3",
          title: "Executive Room",
          description:
            "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../../assets/images/cottages/20220608_164704.jpg"),
            require("../../assets/images/cottages/20220608_174802.jpg"),
            require("../../assets/images/cottages/IMG-20191001-WA0133.jpg"),
          ],
        },
        {
          id: "2",
          title: "Deluxe Room",
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../../assets/images/cottages/20220608_164704.jpg"),
            require("../../assets/images/cottages/20220608_174802.jpg"),
            require("../../assets/images/cottages/IMG-20191001-WA0133.jpg"),
          ],
        },
      ],

      cottage_blocks: [
        {
          id: "1",
          heading: "Standard Cottages",
          brief_description: "Stylish, elegant and contemporary accommodation.",
          image: require("../../assets/images/cottages/20220608_101144.jpg"),
        },
        {
          id: "2",
          heading: "Deluxe Cottages",
          brief_description: "Stylish, elegant and contemporary accommodation.",
          image: require("../../assets/images/cottages/20220608_100948.jpg"),
        },
        {
          id: "3",
          heading: "Family Cottages",
          brief_description: "Stylish, elegant and contemporary accommodation.",
          image: require("../../assets/images/cottages/20220608_151529.jpg"),
        },
        {
          id: "4",
          heading: "Honeymoon Cottages",
          brief_description: "Stylish, elegant and contemporary accommodation.",
          image: require("../../assets/images/cottages/20220608_174802.jpg"),
        },
      ],
    };
  },

  watch: {
    cottage_blocks() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },

  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";
  },

  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
.home_article2 {
  background-color: #ffffff;
  padding-top: 100px;
  padding-bottom: 100px;
}
.home_article3 {
  background-color: #fef9f6;
  padding-top: 100px;
  padding-bottom: 100px;
}
.trekkers_word_style {
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}

.home_welcome_message p {
  font-size: 1.4em;
  font-weight: 300;
}

.cottages_heading {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cottages_heading h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.cottages_heading span {
  font-size: 1.5em;
  font-weight: 600;
  cursor: pointer;
}

.cottages_block {
  background-color: #513f03;
  width: 28vw;
  height: 500px;
  margin: 0px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
  border-radius: 5px;

  position: relative;
}

.cottages_block:hover {
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  transition: 0.5s;
}

.cottages_block img {
  width: 28vw;
  height: 500px;
  object-fit: cover;
}

.welcome_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 30px 30px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  /* border-radius: 5px; */

  display: flex;
  align-items: flex-end;
}

.welcome_description .heading {
  color: white;
  font-size: 1.1em;
  font-weight: 600;
}
.welcome_description .wel_text {
  color: white;
}

.welcome_description ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
}

.welcome_description ul li {
  color: grey !important;
  padding-right: 5px;
}

.welcome_description ul li > i {
  font-size: 1em;
  /* font-weight: 300; */
}

.activities_heading {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activities_heading h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.ttc_activities {
  background-color: #f4f2e4;
}

.ttc_activities_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ttc_activities_grid > div {
  height: 80vh;
}

.ttc_activities_grid > div img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
}

.ttc_activities_grid .ttc_activity_description {
  padding: 50px;
}

.ttc_activities_grid > div h2 {
  font-size: 1.2em;
  font-weight: 500;
}

.ttc_activities_grid > div p {
  font-weight: 300;
}

.affiliates_section {
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
}

.affiliates_section h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.affiliates_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .cottages_block {
    background-color: #513f03;
    width: 50vw;
    height: auto;
    margin: 0px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
    border-radius: 5px;
  }

  .cottages_block img {
    width: 50vw;
  }

  .ttc_activities_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .ttc_activities_grid > div {
    height: 80vh;
  }

  .ttc_activities_grid > div img {
    height: 80vh;
    width: 100%;
    object-fit: cover;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .welcome_description {
    padding: 20px 10px;
    border-radius: 5px;
  }

  .welcome_description .heading {
    font-size: 0.7em;
  }
  .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .cottages_block {
    background-color: #513f03;
    width: 50vw;
    height: auto;
    margin: 0px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.5s;
    border-radius: 5px;
  }

  .cottages_block img {
    width: 50vw;
  }

  .ttc_activities_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .ttc_activities_grid > div {
    height: 100%;
  }

  .ttc_activities_grid > div img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .ttc_activities_grid .ttc_activity_description {
    padding: 30px 20px;
  }

  .ttc_birding {
    grid-auto-flow: row;
    grid-row: 1;
  }

  .ttc_trekking {
    grid-auto-flow: row;
    grid-row: 1;
  }
}
</style>

<style>
.cottages_block {
  background-color: #ec8f97;
  width: 230px;
  height: auto;
  margin: 0px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
  border-radius: 5px;
}

.cottages_block:hover {
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  transition: 0.5s;
}
.el-progress__text {
  font-size: 12px !important;
}
.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .cottages_block {
    width: 230px;
    height: auto;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .cottages_block {
    width: 120px;
    height: auto;
    margin: 0px 5px;
  }
}
</style>