<template>
  <div>
    <article class="theCarouselArticle">
      <el-carousel
        height="100vh"
        :interval="5000"
        arrow="always"
      >

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/cottages/20220608_164433.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-8">
                    <p class="trekkers_word_style">
                      <small>TREKKER'S TAVERN COTTAGES</small>
                    </p>
                    <p>Our cottages mix perfectly with lavish and contemporary comforts and a classical architectural charm of different state.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/horned_chameleon1.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-8">
                    <p class="trekkers_word_style">
                      <small>TREKKER'S TAVERN COTTAGES</small>
                    </p>
                    <p>Experience our fauna and flora in the Bwindi regions like the three horned chameleon, with our tour guides.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/bwindi_forest.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-8">
                    <p class="trekkers_word_style">
                      <small>TREKKER'S TAVERN COTTAGES</small>
                    </p>
                    <p>Feel the fresh air of the Bwindi Impenetrable Forest, and scout the outdoors with friends.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image1.jpg"
              class="d-block w-100"
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-8">
                    <div>
                      <p class="trekkers_word_style">
                        <small>TREKKER'S TAVERN COTTAGES</small>
                      </p>
                      <p>Our Restaurant specializes in various kinds of cuisine cooked by our expert chefs.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

      </el-carousel>
    </article>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      num: 1,
      value1: "",
    };
  },
  methods: {},
};
</script>

<style scoped>
.trekkers_word_style {
  text-transform: uppercase;
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}

.carousel_description {
  position: relative;
  height: 100vh;
}
.carousel_description img {
  height: 100vh;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding-bottom: 5%;
  display: flex;
  align-items: flex-end;
}

.carousel_description .image-description > div {
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  text-align: left;
}

.moreBtn {
  background-color: transparent;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  background-color: #f4f2e4;
  position: relative;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .carousel_description .image-description {
    padding-bottom: 10%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .carousel_description .image-description {
    padding-bottom: 10%;
  }
}
</style>

<style>
.el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.input-class .el-input__inner {
  width: 50px !important;
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 100% !important;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    margin-bottom: 20px;
  }

  .el-input-number.is-controls-right {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
