<template>
  <div>
    <article>
      <CarouselComponent></CarouselComponent>
    </article>

    <section>
      <article>
        <HomeComponent></HomeComponent>
      </article>
    </section>

    <section>
      <article>
        <ReviewComponent></ReviewComponent>
      </article>
    </section>
  </div>
</template>

<script>
import HomeComponent from "../components/home/home-component.vue";
import CarouselComponent from "../components/home/carousel-component.vue";
import ReviewComponent from "../components/home/review-component.vue";

export default {
  components: {
    HomeComponent,
    CarouselComponent,
    ReviewComponent,
  },

  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }
</style>
